// ** React Imports
import { useRef, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { setInitialState } from "store/reducer";
import { logout } from "store/modules/auth";

// ** Third Party Components
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// ** Custom Components
import Block from "components/layout/Block";
import Text from "components/typography/Text";
import LangSelector from "components/layout/LangSelector";
import UserAvatar from "components/user/UserAvatar";
import VipStatus from "components/user/VipStatus";
import ChangeStatus from "components/user/ChangeStatus";
import Spacer from "components/layout/Spacer";
import Login from "components/user/Login";
import Toggle from "components/layout/Toggle/Toggle";
import ServicePageNavMenu from "components/global/ServicePageNavMenu";
import { CardBody } from "components/layout/Card/CardBody";

// ** Custom Hooks
import useDarkMode from "hooks/useDarkMode";
import useLogout from "hooks/useLogout";

// ** Utils & Helpers
import { searchSexCustomSort } from "utils/searchSexCustomSort";
import pseudoFormat from "utils/pseudoFormat";
import generateQueryPath from "utils/generateQueryPath";
import { VIP_STATUS } from "helpers/constants";

// ** Context
import authContext from "contexts/authContext";

// ** Routes
import { routesArray as userpanelRoutesArray } from "containers/Userpanel";

// ** Styled Components
import {
	StyledNavigationMenuListItemLink,
	StyledNavigationMenuListItem,
	StyledNavigationMenuListItemIcon,
	StyledSideMenuCard,
	StyledSideFixed,
} from "./styles";

// ** Lazy Loading Imports
const Menu = loadable(() => import("components/layout/Menu"), { ssr: false });

// ** SVG Static Imports
import { ReactComponent as SolidUserCircle } from "resources/icons/solid/user-circle.svg";
import { ReactComponent as RegularUserCircle } from "resources/icons/regular/user-circle-regular.svg";
import { ReactComponent as SolidImage } from "resources/icons/solid/image-solid.svg";
import { ReactComponent as RegularImage } from "resources/icons/regular/image-regular.svg";
import { ReactComponent as SolidLocationArrow } from "resources/icons/solid/location-arrow-solid.svg";
import { ReactComponent as RegularLocationArrow } from "resources/icons/regular/location-arrow-regular.svg";
import { ReactComponent as SolidUserFriends } from "resources/icons/solid/user-friends.svg";
import { ReactComponent as RegularUserFriends } from "resources/icons/regular/user-friends-regular.svg";
import { ReactComponent as SolidTrophy } from "resources/icons/solid/trophy.svg";
import { ReactComponent as RegularTrophy } from "resources/icons/regular/trophy-regular.svg";
import { ReactComponent as SolidAlignLeft } from "resources/icons/solid/align-left.svg";
import { ReactComponent as RegularAlignLeft } from "resources/icons/regular/align-left-regular.svg";
import { ReactComponent as SolidVideo } from "resources/icons/solid/video.svg";
import { ReactComponent as RegularVideo } from "resources/icons/regular/video-regular.svg";
import { ReactComponent as RegularFilmAlt } from "resources/icons/regular/film-alt-regular.svg";
import { ReactComponent as SolidFilmAlt } from "resources/icons/solid/film-alt-solid.svg";
import { ReactComponent as SolidPhotoVideo } from "resources/icons/solid/photo-video-solid.svg";
import { ReactComponent as RegularPhotoVideo } from "resources/icons/regular/photo-video-regular.svg";

const svgIconsList = {
	["user-circle"]: SolidUserCircle,
	["user-circle-regular"]: RegularUserCircle,
	["image-solid"]: SolidImage,
	["image-regular"]: RegularImage,
	["location-arrow-solid"]: SolidLocationArrow,
	["location-arrow-regular"]: RegularLocationArrow,
	["user-friends"]: SolidUserFriends,
	["user-friends-regular"]: RegularUserFriends,
	["trophy"]: SolidTrophy,
	["trophy-regular"]: RegularTrophy,
	["align-left"]: SolidAlignLeft,
	["align-left-regular"]: RegularAlignLeft,
	["video"]: SolidVideo,
	["video-regular"]: RegularVideo,
	["film-alt-regular"]: RegularFilmAlt,
	["film-alt-solid"]: SolidFilmAlt,
	["photo-video-solid"]: SolidPhotoVideo,
	["photo-video-regular"]: RegularPhotoVideo,
};

const Divider = styled("li").attrs(() => ({
	role: "separator",
}))`
	margin: 0.35rem 0.5rem;
	background: ${({ theme: { colors } }) => colors.buttonsDefaultGrey};
	height: var(--size-grid-small);
`;

// #####################################################

const NavigationMenuListLink = ({ children, ...rest }) => (
	<StyledNavigationMenuListItemLink
		container={StyledNavigationMenuListItem}
		{...rest}
	>
		{children}
	</StyledNavigationMenuListItemLink>
);

// #####################################################

const SideMenu = ({ user, statisticsSelf, mainPageNavItems, language }) => {
	const { t } = useTranslation(["pages"]);
	const isAuth = useContext(authContext);
	const isClient = useSelector((state) => state.global.isClient);

	const logoutFn = useLogout();

	const location = useLocation();
	const isUserpanel = location.pathname.startsWith("/userpanel");

	const containerRef = useRef(null);
	const contentRef = useRef(null);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);
	const { toggleTheme } = useDarkMode();

	const finalUserpanelRoutesArray = user.frozen
		? userpanelRoutesArray.filter(({ meta }) => meta.suspended)
		: userpanelRoutesArray;

	const userCityId = useSelector((state) => state.global.user.city);
	const userRegionId = useSelector((state) => state.global.user.region);
	const userCountryCode = useSelector((state) => state.global.user.country);

	const profileMenuItems = [
		{
			key: "myProfile",
			content: ({ dropdownContainer }) => (
				<Block
					flex
					alignCenter
					style={{ padding: "0 0.5rem 0.5rem" }}
					key="myProfile"
				>
					<UserAvatar
						noOnlineCircle
						userId={user?.id}
						borderSize
						src={user.avatar}
						login={user.login}
						privateData={user.private_data || user.privateData}
						size="xsmall"
						link
					/>
					<Block flex column ml={2}>
						<Login
							login={user.login}
							vip={
								user?.showStatusVip
									? user.status_vip
									: user.status_vip > VIP_STATUS.VERIFIED
									? VIP_STATUS.VERIFIED
									: user.status_vip
							}
							link
							maxWidthWithEllipsis={150}
							vipProps={{ height: 14, marginBottom: 0.125 }}
						/>
						<ChangeStatus dropdownContainer={dropdownContainer} />
					</Block>
				</Block>
			),
		},
		{
			key: "d1",
			content: () => <Divider />,
		},
		...finalUserpanelRoutesArray
			.sort((a, b) => {
				if (a.meta.navIndex > b.meta.navIndex) return 1;
				if (a.meta.navIndex < b.meta.navIndex) return -1;
				return 0;
			})
			.map((route, index) => ({
				key: `${route.key}-${index}`,
				color: "black",
				wrap: route.meta?.wrap,
				content: t(
					(route.meta && route.meta.titleTransKey) || route.key
				),
				link: {
					to: route.link,
					exact: route.exact,
				},
				append: () => {
					if (route.meta && route.meta.menuPillStatKey) {
						const value = Number(
							statisticsSelf[route.meta.menuPillStatKey]
						);

						if (route.meta.menuPillStatKey === "friends") {
							const value2 = Number(statisticsSelf.invities);
							return value > 0 ? (
								<>
									<Block bold ml={2}>
										{pseudoFormat(value)}
									</Block>
									<Spacer flexBasis />
									<Block>
										<Text small>
											{t("common:invites")}{" "}
											<Text bold>
												{pseudoFormat(value2)}
											</Text>
										</Text>
									</Block>
								</>
							) : (
								value2 > 0 && (
									<>
										<Block></Block>
										<Spacer flexBasis />
										<Block>
											<Text small>
												{t("common:invites")}{" "}
												<Text bold>
													{pseudoFormat(value2)}
												</Text>
											</Text>
										</Block>
									</>
								)
							);
						}

						return (
							value > 0 && (
								<Block bold ml={2}>
									{pseudoFormat(value)}
								</Block>
							)
						);
					}
					if (route.meta.menuIconStatKey) {
						return (
							<>
								<Block flex alignCenter ml={2}>
									<Text
										style={{
											marginRight: "5px",
										}}
									>
										<VipStatus
											noPad
											status={4}
											height={14}
										/>
									</Text>
									<VipStatus noPad status={5} height={14} />
								</Block>
							</>
						);
					}
					return null;
				},
			})),
		{
			key: "d3",
			content: () => <Divider />,
		},
		{
			key: "logout",
			content: t("common:logout"),
			color: "primary",
			onClick: () => {
				logoutFn();
			},
		},
	];

	const renderMainPageNavItemsFormated = () => {
		return mainPageNavItems.map((item) => {
			let generatedPath = "";

			const isGallery = item.key === "galleries";

			if (item.key === "nearby") {
				generatedPath = isAuth
					? generateQueryPath(`${item.link.to}`, {
							city: userRegionId ? userCityId : "any",
							region: userRegionId || undefined,
							country: userCountryCode,
							distance: 50,
							sex: user.searchSex
								? user?.searchSex
										.sort(searchSexCustomSort)
										.join("-")
								: "woman-man-couple-transsexual",
					  })
					: "/nearby";
			} else {
				generatedPath = isAuth
					? generateQueryPath(
							`${item.link.to}/${user.urlSearch}`,
							item.link.to === "/search"
								? {
										...item.link.query,
										sex:
											user.searchSex?.join("-") ||
											"woman-man-couple-transsexual",
								  }
								: { ...item.link.query }
					  )
					: user.urlSearch
					? `${item.link.to}/${user.urlSearch}`
					: `${item.link.to}`;
			}

			const generatedPathArray = generatedPath.split("?");
			const isActive = matchPath(location?.pathname, {
				path: item.path,
				exact: isGallery ? true : false,
			});

			return (
				<NavigationMenuListLink
					to={{
						pathname:
							item.link.to === "/search"
								? generatedPathArray[0]
								: item.link.to,
						state: {
							closeFiltersAfterLinkClick: true,
							forceReload: true,
						},
						search: generatedPathArray[1],
					}}
					key={item.key}
					scroll
					isActive={() => isActive}
				>
					<Block flex alignCenter>
						<StyledNavigationMenuListItemIcon
							type={
								item.iconType
									? item.iconType
									: isActive
									? "fas"
									: "far"
							}
							icon={
								isActive ? item.iconActive : item.iconInactive
							}
							svgIconComponent={
								isActive
									? svgIconsList[item.iconActive]
									: svgIconsList[item.iconInactive]
							}
						/>
						<Block flex column>
							<Text bold={Boolean(isActive)}>
								{item.content &&
								typeof item.content === "function"
									? item.content({
											item,
									  })
									: item.content}
							</Text>
							{item.additional && item.additional()}
						</Block>
					</Block>
				</NavigationMenuListLink>
			);
		});
	};

	const mainPageNavItemsFormated = renderMainPageNavItemsFormated();

	// #####################################################

	return (
		<StyledSideMenuCard
			ref={containerRef}
			extended={isUserpanel}
			isAuth={isAuth}
		>
			<CardBody fullHeight>
				<StyledSideFixed
					ref={contentRef}
					extended={isUserpanel}
					isAuth={isAuth}
				>
					{isAuth && isUserpanel && isClient && (
						<Menu
							items={profileMenuItems}
							dropdownContainer={contentRef?.current}
							userpanelMenu
							userpanelSideMenu
							customPadding="0.5rem"
						/>
					)}
					{!isUserpanel && isClient && (
						<ul>{mainPageNavItemsFormated}</ul>
					)}
					{!isUserpanel && !isClient && (
						<ul>{mainPageNavItemsFormated}</ul>
					)}
					<Spacer />
					<Block flex column>
						{/* Przełącznik Trybu Ciemny / Jasny */}
						<Block px={2} flex alignCenter id="variantDark" mt={4}>
							<Text color="grey" size="0.9rem" mr={2}>
								{t("common:darkMode")}:{" "}
							</Text>
							<Toggle
								onToggle={() => toggleTheme()}
								toggled={isDarkMode}
							/>
						</Block>
						<Block px={2} mt={5}>
							<Text color="grey" size="0.9rem">
								{t("common:language")}:{" "}
							</Text>
							<LangSelector
								language={language}
								label={t("common:language")}
							/>
						</Block>
						<Block px={2} mt={4}>
							<Text color="grey" size="0.9rem">
								{t("common:service")}:{" "}
							</Text>
							<ServicePageNavMenu />
						</Block>
					</Block>
				</StyledSideFixed>
			</CardBody>
		</StyledSideMenuCard>
	);
};

const mapStateToProps = ({
	global: { user, statistics, statisticsSelf, language },
}) => ({
	user,
	statistics,
	statisticsSelf,
	language,
});

export default connect(mapStateToProps, {
	logout,
	setInitialState,
})(SideMenu);
